<template>
	<div>
		<v-overlay :value="overlay" absolute >
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-toolbar flat>
			<v-icon left>mdi-filter-cog-outline</v-icon>
			<v-toolbar-title>ETAPAS DE VENTA</v-toolbar-title>
			<v-divider class="mx-4" vertical></v-divider>
			<v-btn @click="abrirPipelineDialog(null)" color="blue" small dark>
		<v-icon left>mdi-plus</v-icon> Crear
		</v-btn>
			<v-divider class="mx-4" vertical></v-divider>

			<v-btn @click="borrarPipeline()" color="blue" small dark
				><v-icon left>mdi-delete</v-icon>Borrar</v-btn
			>
		</v-toolbar>
		<v-data-table
			:headers="headers"
			:items-per-page="itemsPerPage"
			:items="pipelines"
			class="elevation-1"
			small
			hide-default-footer
			v-model="selitem"
			item-key="pipeline_id"
			single-select
			show-select
		>
			<template v-slot:item.pipeline_id="{ item }">
				<v-btn text small @click="abrirPipelineDialog(item.pipeline_id)">
					{{ item.pipeline_id }}
				</v-btn>
			</template>
			<template v-slot:item.activo="{ item }">
				<v-btn
					title="activar"
					v-if="item.activo == false"
					text
					small
					@click="activarPipeline(item.pipeline_id)"
				>
					<v-icon color="green">mdi-check</v-icon>
				</v-btn>
				<v-btn
					title="desactivar"
					v-if="item.activo == true"
					text
					small
					@click="activarPipeline(item.pipeline_id)"
				>
					<v-icon color="red">mdi-cancel</v-icon>
				</v-btn>
				{{ item.activo == true ? "ACTIVO" : "INACTIVO" }}
			</template>
			<template v-slot:footer>
				<v-pagination
					class="mt-10"
					v-model="currentPage"
					:length="pageCount"
					@input="handlePageChange"
					total-visible="10"
				></v-pagination>
			</template>
		</v-data-table>
		<!-- dialogo pipeline -->
		<v-dialog v-model="dialogoPipeline" scrollable persistent width="700">
			<v-card>
				<v-toolbar dark color="blue" fixed height="40" flat>
					<v-icon left>mdi-filter-cog-outline</v-icon>
					Pipeline
					<v-spacer></v-spacer>
					<v-btn small text @click="dialogoPipeline = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
				<v-form ref="formPipeline">
					<v-col cols="12" class="py-0 my-0">
						<v-text-field
							v-model="datosPipeline.pipeline_name"
							label="Nombre"
							:rules="requiredRule"
						></v-text-field>
					</v-col>
					<v-col cols="12" class="py-0 my-0">
						<v-text-field
							v-model="datosPipeline.description"
							label="Descripción"
						></v-text-field>
					</v-col>
				</v-form>
				<v-row v-if="datosPipeline.pipeline_id != ''">
					<v-col cols="12">
						<v-data-table
							:headers="headerEtapas"
							:items-per-page="itemsPerPageEtapa"
							:items="etapas"
							class="elevation-1"
							hide-default-footer
							small
						>
							<template v-slot:top>
								<v-toolbar height="40" flat>
									<v-toolbar-title>Etapas </v-toolbar-title>
									<v-divider vertical class="px-2"></v-divider>
									<v-btn
										small
										rounded
										color="primary"
										title="Crear etapa"
										@click="abrirDialogoEtapa"
										><v-icon>mdi-plus</v-icon></v-btn
									>
								</v-toolbar>
							</template>

							<template v-slot:item.opciones="{ item }">
								<v-btn
									v-if="item.stage_type != 'FINAL' && item.stage_type != 'INICIO'"
									small
									icon
									title="Borrar etapa"
									@click="borrarEtapa(item.opportunity_stage_id)"
								>
									<v-icon small color="red">mdi-delete</v-icon>
								</v-btn>
								<v-btn
									small
									icon
									title="Editar etapa"
									@click="editarEtapa(item.opportunity_stage_id)"
								>
									<v-icon small color="green">mdi-pencil</v-icon>
								</v-btn>
								<v-btn
									small
									icon
									title="Motivos"
									@click="openDialogMotivos(item.opportunity_stage_id)"
								>
									<v-icon small color="green">mdi-dots-horizontal</v-icon> {{ item.cantidad }}
								</v-btn>
							</template>
						</v-data-table>
					</v-col>
					
				</v-row>
				</v-card-text>
				<v-toolbar flat>
					<v-btn color="primary" small @click="guardarPipeline">
						<v-icon left>mdi-content-save</v-icon>Guardar
					</v-btn>
					<v-divider vertical class="px-2" inset></v-divider>
					<v-btn text small @click="dialogoPipeline = false">
						<v-icon left>mdi-cancel</v-icon>Cancelar
					</v-btn>
				</v-toolbar>
			</v-card>
		</v-dialog>
		<!-- dialogo pipeline -->

		<!-- dialogo etapa -->
		<v-dialog v-model="dialogoEtapa" scrollable persistent width="400">
			<v-form ref="formEtapa">
				<v-card>
					<v-toolbar dark color="blue" fixed height="40" flat>
						Etapa
						<v-spacer></v-spacer>
						<v-btn small text @click="dialogoEtapa = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar>
					<v-card-text>
					<v-row class="mx-2 my-2">
						<v-col cols="12" class="py-0 my-0"
							><v-text-field
								v-model="datosEtapa.description"
								label="Nombre"
								:rules="requiredRule"
							></v-text-field>
						</v-col>
						<v-col cols="12" class="py-0 my-0"
							><v-text-field
								v-model="datosEtapa.sequence_num"
								:rules="(requiredRule, validarSecuencia)"
								label="Secuencia"
							></v-text-field>
						</v-col>
						<v-col cols="12" class="py-0 my-0"
							><v-text-field
								v-model="datosEtapa.default_probability"
								:rules="requiredRule"
								label="Probabilidad"
							></v-text-field>
						</v-col>
						<v-col cols="12" class="py-0 my-0"
							><v-textarea label="Guia" v-model="datosEtapa.guia"></v-textarea>
						</v-col>
					</v-row>
				</v-card-text>
					<v-toolbar flat>
						<v-btn color="primary" small @click="guardarEtapa">
							<v-icon left>mdi-content-save</v-icon>Guardar
						</v-btn>
						<v-divider vertical class="px-2" inset></v-divider>
						<v-btn text small @click="dialogoEtapa = false">
							<v-icon left>mdi-cancel</v-icon>Cancelar
						</v-btn>
					</v-toolbar>
				
				</v-card>
			</v-form>
		</v-dialog>
		<v-dialog 
			v-model="dialogMotivos" 
			scrollable 
			persistent 
			width="800px"
		>	
			<v-card>
				<v-form ref="formEventos">
					<v-toolbar dark color="blue" height="40" flat>
						<v-icon left>mdi-list-status</v-icon> Motivos
						<v-spacer></v-spacer>
						<v-btn small text @click="dialogMotivos = false">
						<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar>
					<v-data-table
						:items="listaMotivos"
						:headers="headersMotivos"
						hide-default-footer
					>
						<template v-slot:item.opciones="{ item }">
							<v-btn
								small
								icon
								color="green"
								title="Editar motivo"
								@click="editarMotivo(item)"
							>
								<v-icon small>mdi-pencil</v-icon>
							</v-btn>
							</template>
							<template v-slot:item.estado="{ item }">
							<span v-if="item.estado == true">SI</span>
							<span v-if="item.estado == false">NO</span>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
								<v-icon
									:color="item.estado == true ? 'green' : 'grey'"
									class="mx-2"
									v-bind="attrs"
									v-on="on"
									@click="estadoMotivo(item)"
								>
									{{ item.estado == true ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
								</v-icon>
								</template>
								<span>Cambiar estado</span>
							</v-tooltip>
						</template>
					</v-data-table>
					<v-col cols="12">
						<v-row>								
							<v-col md="6">
								<v-text-field
									:rules="requiredRule"
									v-model="datosMotivo.descripcion"
									label="Descripción"
								></v-text-field>
							</v-col>

							<v-col md="4">
								<v-text-field
									:rules="requiredRule"
									v-model="datosMotivo.secuencia"
									label="Secuencia"
								></v-text-field>
							</v-col>
						</v-row>
					</v-col>
					<v-toolbar>
						<v-btn color="primary" small @click="guardarMotivo">
						<v-icon small left>mdi-content-save</v-icon>Guardar
						</v-btn>
						<v-divider vertical class="px-2" inset></v-divider>
						<v-btn text small @click="closeDialogMotivos">
						<v-icon small left>mdi-cancel</v-icon>Salir
						</v-btn>
					</v-toolbar>
				</v-form>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
	name: "Pipelines",
	data: () => ({
		requiredRule: [(v) => !!v || "El campo es requerido"],
		options: {},
		setfilters: [],
		users: [],
		totalItems: 1,
		currentPage: 1,
		itemsPerPage: 20,
		itemsPerPageEtapa: 20,
		itemsPerMotivo: 20,
		pageCount: 0,
		overlay: false,
		idMotivo:null,
		dialogMotivos:false,
		listaMotivos:[],
		selitem: [],
		required: [(v) => !!v || "El campo es requerido"],
		headers: [
			{ text: "ID", align: "start", value: "pipeline_id" },
			{ text: "Nombre", align: "start", value: "pipeline_name" },
			{ text: "Estado", align: "start", value: "activo" },
		],
		headersMotivos:[
			{ text: "Opciones", align: "start", value: "opciones" },
			{ text: "ID", align: "start", value: "sale_opportunity_stage_motivo_id" },
			{ text: "Nombre", align: "start", value: "descripcion" },
			{ text: "Secuencia", align: "start", value: "secuencia" },
			{ text: "Estado", align: "start", value: "estado" },
		],
		pipelines: [],
		dialogoPipeline: false,
		datosPipeline: {
			pipeline_id: "",
			pipeline_name: "",
			description: "",
			activo: true,
		},
		datosEtapa: {
			pipeline_id: "",
			description: "",
			sequence_num: "",
			default_probability: "",
		},
		headerEtapas: [
			{ text: "Opciones", align: "center", value: "opciones" },
			{ text: "ID", align: "start", value: "opportunity_stage_id" },
			{ text: "Tipo", align: "start", value: "stage_type" },
			{ text: "Nombre", align: "start", value: "description" },
			{ text: "Secuencia", align: "end", value: "sequence_num" },
			{ text: "Probabilidad", align: "end", value: "default_probability" },
		],
		datosMotivo: {},
		oppStageMotivoId:null,
		etapas: [],
		dialogoEtapa: false,
	}),
	computed: {

		...mapState("master", [
			"loadingBtn",
			"companies",
			"paramAlertQuestion",
			"loadingBtn",
		]),

		...mapState("crm", ["menu"]),

		...mapState("master", ["loadingTable", "tenantId", "user"]),

		validarPrioridad() {
			return this.validarNumerico(this.datosEtapa.default_probability);
		},

		validarSecuencia() {
			return this.validarNumerico(this.datosEtapa.sequence_num);
		}

	},
	methods: {

		//234 celina
		...mapMutations("master", ["setUrl","setLoadingTable","setTitleToolbar","setTenantId"]),

		...mapActions("master", ["requestApi", "alertNotification"]),

		...mapActions("access", []),

		validarNumerico(num){
			if (num.length > 3) {
				return ["Número inválido"];
			}
			if (isNaN(num)) {
				return ["Número inválido"];
			} else {
				return [true];
			}
		},

		cargarDatos() {

			this.setLoadingTable(true);
			this.overlay = true;
			this.setUrl("pipeline");

			this.requestApi({
				method: "GET",
				data: {
					page: this.currentPage,
					itemsPerPage: this.itemsPerPage,
					paginator: true,
					typeList: 'cargarPipeline'
				},
			})
			.then((res) => {
				//console.log(res.data);
				this.pipelines = res.data._embedded.pipeline;
				this.pageCount = res.data.page_count;
				this.currentPage = res.data.page;
				this.totalItems = res.data.total_items;
				this.overlay = false;
			})
			.then(() => {
				this.setLoadingTable(false)
				this.overlay = false
			})

		},

		abrirPipelineDialog(pipelineId) {
			this.dialogoPipeline = true;
			this.datosPipeline = {
				pipeline_id: "",
				pipeline_name: "",
				description: "",
			};

			//console.log(pipelineId)
			if (pipelineId) {
				this.cargarDatosPipeline(pipelineId);
			}
		},

		cargarDatosPipeline(id) {
			this.setLoadingTable(true);
			this.setUrl("pipeline/" + id);
			this.requestApi({
				method: "GET",
				data: {},
			})
				.then((res) => {
					//console.log(res.data);
					this.datosPipeline = res.data.pipeline;
					this.etapas = res.data.etapas;
				})
				.then(() => {
					this.setLoadingTable(false);
				});
		},

		guardarPipeline() {

			if (!this.$refs.formPipeline.validate())
				return false
			
			this.setLoadingTable(true);
			this.setUrl("pipeline");
			this.requestApi({
				method: "POST",
				data: {
					accion: "guardarPipeline",
					pipeline: this.datosPipeline,
				},
			})
			.then((res) => {
				//console.log(res.data);
				this.alertNotification({
					param: {
						html: res.data.detail.msg,
					},
				})

				this.cargarDatosPipeline(res.data.detail.pipeline_id)

				if (this.datosPipeline.pipeline_id != "") {
					this.dialogoPipeline = false;
					this.cargarDatos();
				}

			})
			.then(() => {
				this.setLoadingTable(false);
			})

		},

		borrarPipeline() {
			this.setUrl("pipeline");
			this.requestApi({
				method: "DELETE",
				data: {
					accion: "borrarPipeline",
					pipeline_id: this.selitem[0].pipeline_id,
				},
			})
				.then((res) => {
					//console.log(res.data);
					this.alertNotification({
						param: {
							html: res.data.detail.msg,
						},
					});
					this.dialogoPipeline = false;
				})
				.then(() => {
					this.cargarDatos();
					this.setLoadingTable(false);
				});
		},

		activarPipeline(plId) {},

		borrarEtapa(osid) {
			Vue.swal({
				html: "Está seguro de eliminar esta etapa ?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Aceptar",
				cancelButtonText: "Cerrar",
				...this.paramAlertQuestion,
			}).then((result) => {
				if (result.isConfirmed) {
					this.setUrl("pipeline");
					this.requestApi({
						method: "POST",
						data: {
							accion: "borrarEtapaVenta",
							opportunity_stage_id: osid,
						},
					})
						.then((res) => {
							//console.log(res.data);
							this.alertNotification({
								param: {
									html: res.data.detail.msg,
								},
							});
							this.cargarDatosPipeline(this.datosPipeline.pipeline_id);
						})
						.then(() => {
							this.setLoadingTable(false);
						});
				}
			});
		},

		abrirDialogoEtapa(opstageId) {
			this.datosEtapa = {
				pipeline_id: this.datosPipeline.pipeline_id,
				description: "",
				sequence_num: "",
				default_probability: "",
			};
			this.dialogoEtapa = true;
		},

		editarEtapa(opstageId) {
			this.setUrl("pipeline");
			this.requestApi({
				method: "POST",
				data: {
					accion: "cargarDatosEtapaVenta",
					opportunity_stage_id: opstageId,
				},
			})
				.then((res) => {
					//console.log(res.data);
					this.datosEtapa = res.data.detail;
					this.dialogoEtapa = true;
				})
				.then(() => {
					this.setLoadingTable(false);
				});
		},

		guardarEtapa() {
			if (!this.$refs.formEtapa.validate()) {
				return false;
			}
			this.setUrl("pipeline");
			this.requestApi({
				method: "POST",
				data: {
					accion: "guardarEtapaVenta",
					datosEtapa: this.datosEtapa,
				},
			})
				.then((res) => {
					//console.log(res.data);
					this.alertNotification({
						param: {
							html: res.data.detail.msg,
						},
					});
					this.dialogoEtapa = false;
					this.cargarDatosPipeline(this.datosPipeline.pipeline_id);
				})
				.then(() => {
					this.setLoadingTable(false);
				});
		},

		handlePageChange(value) {
			this.cargarDatos();
		},

		openDialogMotivos(oppStageMotivoId){

			this.dialogMotivos = true
			this.oppStageMotivoId = oppStageMotivoId
			this.cargarMotivos()
			
		},

		closeDialogMotivos(){

			this.dialogMotivos = false
			this.oppStageMotivoId = null
			this.listaMotivos = []
			this.datosMotivo = {}

		},
	
		cargarMotivos(){

			this.setLoadingTable(true);
			this.overlay = true;
			this.setUrl("pipeline");

			this.requestApi({
				method: "GET",
				data: {
					page: this.currentPage,
					itemsPerPage: this.itemsPerPage,
					paginator: true,
					oppStageMotivoId: this.oppStageMotivoId,
					typeList: 'cargarMotivos'
				},
			})
			.then((res) => {
				console.log(res.data);
		
				this.listaMotivos = res.data._embedded.pipeline
				this.overlay = false;
			})
			.then(() => {
				this.setLoadingTable(false)
				this.overlay = false
			})

		},

		editarMotivo(motivo){
				console.log('motivo',motivo)
			this.datosMotivo = motivo
			this.oppStageMotivoId = motivo.opportunity_stage_id
			
		},

		estadoMotivo(motivo){

			this.setLoadingTable(true)

			this.setUrl("pipeline")

			this.requestApi({
				method: "POST",
				data: {
					accion: "actualizaEstadoMotivo",
					sale_opportunity_stage_motivo_id: motivo.sale_opportunity_stage_motivo_id
				},
			})
			.then((res) => {

				console.log(res.data)
				this.alertNotification({
					param: {
						html: res.data.detail.msg,
					},
				})
				
				this.cargarMotivos()

			}).then(() => {

				this.setLoadingTable(false)

			})

		},

		listadoMotivos(){

			this.setLoadingTable(true);

			this.setUrl("estado-evento");

			this.requestApi({
				method: "GET",
				data: {
					page: this.currentPage,
					page_size: this.itemsPerPage,
					paginator: true,
					status_type_id: this.buscarEstadoPorTipo,
					tipo_lista: "listaMotivos",
					status_description: this.buscarEstadoNombre
				},
			}).then((res) => {
				//console.log(res.data);
				this.estados = res.data._embedded.estado_evento
				this.pageCount = res.data.page_count
				this.currentPage = res.data.page
				this.totalItems = res.data.total_items

			}).then(() => {
				this.setLoadingTable(false);
			})

		},


		guardarMotivo(){

			if (!this.$refs.formEventos.validate()) 
				return false;
			
			this.setLoadingTable(true)

			this.setUrl("pipeline")

			this.requestApi({
				method: "POST",
				data: {
					accion: "guardarMotivo",
					datosMotivo: this.datosMotivo,
					oppStageMotivoId: this.oppStageMotivoId
				},
			})
			.then((res) => {

				console.log(res.data)
				this.alertNotification({
					param: {
						html: res.data.detail.msg,
					},
				})
				
				this.datosMotivo = {}
				this.cargarMotivos()

			}).then(() => {

				this.setLoadingTable(false)

			})

		}

	},
	mounted() {
		this.cargarDatos();
		this.setTitleToolbar("ETAPAS DE VENTA");
	},
};
</script>
